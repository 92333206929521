<template>
    <component :is="component" v-if="component"  />
</template>

<script>

    import {mapState, mapGetters} from "vuex";
    export default {
        name: 'PageHeader',
        components: {},
        mixins: [],
        props: {
        },
        data() {
            return {
                type: 0, //0: 관리자, 1: 멘토, 2: 멘티, 3: 센터
            }
        },
        created(){

        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            }),
            component() {
                if(this.UserInfo.mb_type === 0) {
                    return () => import(`@/components/header/AdminHeader`)
                }else if(this.UserInfo.mb_type === 1) {
                    return () => import(`@/components/header/MentorHeader`)
                }else if(this.UserInfo.mb_type === 2) {
                    return () => import(`@/components/header/MenteeHeader`)
                }else if(this.UserInfo.mb_type === 3) {
                    return () => import(`@/components/header/CenterHeader`)
                }else{
                    return () => import(`@/components/header/LogoutHeader`)
                }
            }
        },
        methods:{
            UserLogout() {
            },
        },
        watch: {

        }
    }

</script>
