import Vue from 'vue';
import {request, setAuthInHeader} from "@/api/request";
// import {req} from "vuelidate/src/validators/common";


Vue.prototype.$api = {
    $auth: {
        login(params) {
            return request.post(`userinfo/login`, params).then(({data}) => data);
        },
        findId(params){
            return request.get(`userinfo/find/id`, params).then(({data}) => data);

        },
        findPw(params){
            return request.post(`userinfo/send/passwd`, params).then(({data}) => data);
        },
        regist(params) {
            return request.post(`userinfo`, params).then(({data}) => data);
        }
    },
    $member: {
        sendPhoneCeritify(params){
            return request.post(`userinfo/send/smscertify`, params).then(({data}) => data);
        },
        checkPhoneCertify(params) {
            return request.post(`userinfo/get/smscertify`, params).then(({data}) => data);
        },
        getJobCategory(params) {
            return request.get(`userinfo/job/category`, params).then(({data}) => data);
        },
        checkIdOverLap(params) {
            return request.get(`userinfo/chk/id`, params).then(({data}) => data);
        },
        getMemberList(params){
            return request.get(`userinfo`, params).then(({data}) => data);
        },
        approveMember(params){
            return request.post('userinfo/approve',params).then(({data}) => data);
        },
        getMentor(params) {
            return request.get(`userinfo/mentor`, params).then(({data}) => data);
        },
        updatePassword(params){
            return request.post('userinfo/update/passwd', params).then(({data}) => data);
        },
        updateMember(params) {
            return request.post(`userinfo/update`, params).then(({data}) => data);
        },
        deleteMember(params){
            return request.post(`userinfo/delete`, params).then(({data}) => data);
        },
        getQuitCategory(params){
            return request.get(`userinfo/out/category`, params).then(({data}) => data);
        },


    },
    $config: {
        getConfig(params) {
            return request.get(`config`, params).then(({data}) => data);
        }
    },
    $board: {
        getLetter(params) {
            return request.get(`letter`, params).then(({data}) => data);
        },
        getJobCategory(params) {
            return request.get(`userinfo/job/category`, params).then(({data}) => data);
        },
        getProvider(params) {
            return request.get(`provider`, params).then(({data}) => data);
        },
        likeLetter(params) {
            return request.post(`board/letter/like`, params).then(({data}) => data);
        },
        createLetter(params) {
            return request.post(`letter`, params).then(({data}) => data);
        },
        updateLetter(params) {
            return request.post(`letter/update`, params).then(({data}) => data);
        },
        deleteLetter(params) {
            return request.post(`letter/delete`, params).then(({data}) => data);
        },
        blindLetter(params) {
            return request.post(`board/letter/blind`, params).then(({data}) => data);
        },
        getCommu(params) {
            return request.get(`free`, params).then(({data}) => data);
        },
        likeCommu(params) {
            return request.post(`board/free/like`, params).then(({data}) => data);
        },
        createCommu(params) {
            return request.post(`free`, params).then(({data}) => data);
        },
        updateCommu(params) {
            return request.post(`free/update`, params).then(({data}) => data);
        },
        deleteCommu(params) {
            return request.post(`free/delete`, params).then(({data}) => data);
        },
        blindCommu(params) {
            return request.post(`board/free/blind`, params).then(({data}) => data);
        },
        getNotice(params) {
            return request.get(`board/notice`, params).then(({data}) => data);
        },
        getMyCommentLetter(params){
            return request.get(`letter/my/comment`, params).then(({data}) => data);
        },
        getMyLikeLetter(params){
            return request.get(`letter/my/like`, params).then(({data}) => data);
        },
        getMyCommentCommu(params){
            return request.get(`free/my/comment`, params).then(({data}) => data);
        },
        getMyLikeCommu(params){
            return request.get(`free/my/like`, params).then(({data}) => data);
        },
        getFaqCategory(params){
            return request.get(`faq/category`, params).then(({data}) => data);
        },
        getFaq(params){
            return request.get(`faq`, params).then(({data}) => data);
        },
        getMentorCommu(params){
            return request.get(`mentor`, params).then(({data}) => data);
        },
        createMentorCommu(params){
            return request.post('mentor',params).then(({data}) => data);
        },
        updateMentorCommu(params) {
            return request.post(`mentor/update`, params).then(({data}) => data);
        },
        blindMentorCommu(params) {
            return request.post(`board/mentor/blind`, params).then(({data}) => data);
        },
        deleteMentorCommu(params) {
            return request.post(`mentor/delete`, params).then(({data}) => data);
        },
        likeMentorCommu(params) {
            return request.post(`board/mentor/like`, params).then(({data}) => data);
        },
        getMyCommentMentorCommu(params){
            return request.get(`mentor/my/comment`, params).then(({data}) => data);
        },
        getMyLikeMentorCommu(params){
            return request.get(`mentor/my/like`, params).then(({data}) => data);
        },
        getMyPostMentorCommu(params){
            return request.get(`mentor/my/post`, params).then(({data}) => data);
        },
        getAmidan(params) {
            return request.get(`board/activity`, params).then(({data}) => data);
        },


    },
    $banner: {
        getBanner(params) {
            return request.get(`popup`, params).then(({data}) => data);
        }
    },
    $alarm: {
        deleteAlarm(params) {
            return request.post(`alarm/delete`, params).then(({data}) => data);
        },
    },
    $file: {
        updateFile(params) {
            return request.post(`file`, params).then(({data}) => data);
        },
        deleteFile(params) {
            return request.post("file/delete/"+params.a_idx)
                .then(({data}) => data);
        },
    },
    $handler: {
        authHeader : setAuthInHeader
    }
};

export default Vue.prototype.$api;
