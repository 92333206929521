 <template>
<div>
  <div class="f_back" >
    <div class="f_wrap">
      <div class="f_w_box">
        <div class="f_logo">
          <!-- <div class="eht_chul" @click="fcMove('/')">아빠 미소 <span>멘토단</span></div> -->
           <div class="logo_ami logo_white" @click="ClickHome('/')">
           <img src="@/assets/image/rebuild/ami_logo_white.png" alt="">
          </div>
<!--          <div class="logo_lotte logo_white"><img  src="@/assets/image/rebuild/lotte_logo_white.png" alt=""> </div>-->

        </div>
        <div class="f_end">
          <div class="footer_txt">
            <div class="f_txt_list ftl_bold">
              사단법인 함께하는아버지들
            </div>
          </div>
          <div class="footer_txt">
            <div class="f_txt_list">
              서울특별시 중구 퇴계로 18길10 호경빌딩 301호
            </div>
          </div>
          <div class="footer_txt">
            <div class="f_txt_list">
              전화  02-415-7955
            </div>
            <div class="f_txt_list">
              팩스  0504-191-4159
            </div>
            <div class="f_txt_list">
                mail@fathers.or.kr
            </div>
          </div>
        </div>
        <terms-modal></terms-modal>
      </div>
    </div>
  </div>
</div>
</template>
<script>
  //components
  import TermsModal from '@/components/modal/TermsModal';

  export default {
    name: 'PageFooter',
    props: {
      msg: String
    },
    components: {
        TermsModal
    },
    data() {
      return {
        isModalOpen: false,
        modalName: 'privacy',
        cfData: {
          cf_addr : '',
          cf_boss_name: '',
          cf_business_regi: '',
          cf_comm_regi: '',
          cf_corp_regi: '',
          cf_fax: '',
          cf_name: '',
          cf_online_regi: '',
          cf_policy_name: '',
          cf_policy_email: '',
          cf_tel: ''
        },
        noticeList: [],
      }
    },
    mounted() {
      this.getDefaultInfo()
    },
      mounted(){
          let move = 0;
          let order=0;

          function timeout() {
                setTimeout(() => {
                    let height = $(".m_notice").height();
                    let num = $(".m_n_rolling").length;
                    let max = height*num;
                    move += height;
                    $(".rolling_wrap").animate({"top":-move},600,function () {
                        if(move >= max){
                            $(this).css("top",0);
                            move = 0;
                        };
                    });

                    $(".m_n_rolling").eq(order).clone().appendTo($(".rolling_wrap"));


                    order += 1;
                        if(order >4){
                            order = 0;

                        }
                    timeout();

                },10000);

          }
          // timeout();
      },
    methods:{
      ClickHome() {
        if(this.$route.meta.name === 'Card') {
          let children = this.$parent.$children;
          children.forEach(com => {
            if(com.hasOwnProperty('setCategory')) {
              com.setCategory('');
            }
            if(com.hasOwnProperty('setStatus')) {
              com.setStatus('');
            }
          })
        }else{
          this.$router.push('/');
        }
      },
        fcMove(path) {
          console.log(path)
            this.$router.push(path);
        },
      getDefaultInfo() {
        this.$api.$common.GetSite().then(data => data.Data.result).then(data => {
          this.cfData = data;
        });
      },
      getNoticie() {
        this.$api.$notice.getNotice().then(data => {
          if(data.Data.Result.hasOwnProperty('List')) {
            this.noticeList = data.Data.Result.List.slice(0, 5);
          }
        })
      },
      openModal(name) {
        this.$modal.show(name);
      },

    }
  }
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
